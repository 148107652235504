.txt1-Echanges{
    color: var(--neutral-0, #222);
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 48px;
}
.pagination-echn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.conterM-echange{
    display: flex;
    gap: 6em; 
}
.span-echange{
    color: var(--Neutral-0, #222);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
}
.text-echange {
    color: var(--Neutral-0, #222);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 150px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}
.Article-data{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    padding-bottom: 4%;
}
.Article-data-none{
    display: none;
}
.colM-echange{
    display: flex;
    flex-direction: column;
    gap: 2em;    position: relative;

}
.bnt-m1{
    width: 206px;
height: 56px;
flex-shrink: 0;
border-radius: 8px;
background: #222;
color: #FFF;
cursor: pointer;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px;
}
.inputM-echa{
    width: 708px;
height: 64px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
}
.colM-ech{
    display: flex;
    flex-direction: column;
    gap: 0.7em; 
}
.input-echM{
    width: 251px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
}
.line-mechange{
    position: absolute;
    height: 685px;
    flex-shrink: 0;
    top: 2%;
    left: 24%;
    border: 1px solid #E1E1E1;
    background: var(--fill-color-white, #FFF);
}
.input-ech {
    width: 366px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
}
.input-int2{
    width: 56px;
height: 48px;
flex-shrink: 0;
border-radius: 8px 0px 0px 8px;
border: 1px solid #E1E1E1;
background: var(--fill-color-white, #FFF);
}
.flex-end{
    display: flex;
    justify-content: end;
    cursor: pointer;
}
.col-modal{
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.txt1-modal
{
    color: #222;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.txt2-modal{
    color: #222;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
width: 416px;

}
.row-Echange{
    display: flex;
    justify-content: space-between;
}
.bnt-Echange{
    cursor: pointer;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
width: 180px;
height: 48px;
flex-shrink: 0;
border-radius: 8px;
background: var(--neutral-0, #222);
}
td{
    width: auto;
}
.txt010-echange {
    color: var(--neutral-0, #222);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.txt01-echange {
    color: var(--neutral-0, #222);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.scrolling{
    width: 206px;
    height: 280px;
    overflow: auto;
    text-align: justify;
    overflow-x: hidden;
    gap: 2em;
}
.hoverscro:hover {
    background-color: #E9B949;
    cursor: pointer;
  }

.scrolling-model{
    cursor: grab;
    width: 120%;
    overflow: auto;
    text-align: justify;
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 1em;

 }
 .scrolling-model::-webkit-scrollbar {
   width: 12px;
   }
.col2-modal{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-left: 10%;
    padding-right: 10%;

}
.bnt1-modal{

    cursor: pointer;
    width: 194px;
height: 56px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #E1E1E1;
background: #FFF;
color: #222;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px;
}

.bnt2-modal{
    cursor: pointer;
    width: 206px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #222;
    color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px;
}
.bntrow-model{
    display: flex;
    gap: 1.5em;
}
.input-modal{
    width: 416px;
height: 48px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #E1E1E1;
background: var(--fill-color-white, #FFF);
}
.boxQté-model{
    color: #747474;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;

}
.stats-vensder{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #05400A;
    width: 88px;
    height: 32px;
    background: #C1EAC5;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stats-admin4 {
    width: 88px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 36px;
    background: var(--orange-800, #FACDCD);
    color: var(--red-0, #610404);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Echange{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 4em;
}
.tab{
    display: flex;
    flex-direction: column;
    gap: 4.5em;   
}
.col-lispr{
    display: flex;
    flex-direction: column;
    gap: 2em
}

.scrolingprod{
    display: flex;
    flex-direction: column;
    gap: 1em
}
.inputtext-tab{
    width: 332px;
height: 48px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #E1E1E1;
background: var(--fill-color-white, #FFF);
padding-left: 2%;
}
.inputtext-tab2{
    width: 332px;
}
.img-tabliste{
    width: 28px;
height: 28px;
flex-shrink: 0;
border-radius: 4px;
background-blend-mode: multiply, normal;
}
.checkbox-tabliste{
    width: 24px;
height: 24px;
color: #222222;
}
.row-trb{
    display: flex;
    gap: 1em;
    align-items: center;
}
.txtbox-trb{
    width: 350px;
    color: #222;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
}
.box-Echanges{

flex-shrink: 0;
border-radius: 8px;
background: #FFF;
display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 2%;
}
.stats-admin3{
    width: 88px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 36px;
    background: var(--orange-800, #FFD3BA);
    color: var(--orange-0, #572508);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 14px; 
display: flex;
justify-content: center;
align-items: center;
}
.stats-admin4{
    width: 88px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 36px;
    background: var(--orange-800, #FACDCD);
    color: var(--red-0, #610404);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 14px; 
display: flex;
justify-content: center;
align-items: center;
}