.prod-lst{
width: 208px;
height: 276px;
background: #fff;
box-shadow: 0px 5px 30px rgba(207, 207, 207, 0.2);
border-radius: 4px;
display: flex;
flex-direction: column;
gap:1em;
padding: 7%;
cursor: pointer;
}
.prod-lst:hover{
    transform: scale(1.08)
 }
.img-lst{
 
    Width:172px;
Height:144px;
 background-blend-mode: multiply, normal;
border-radius: 2px;

}
.img-lst2{
 
    Width:172px;
Height:144px;
 background-blend-mode: multiply, normal;
border-radius: 2px;
margin-top: -20%;
}

.txt-lst{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #7C5E10;

}
.txt-lst2{
width: auto;
height: 40px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #222222;
white-space: nowrap;       
overflow: hidden;          
text-overflow: ellipsis;    
max-width: 100%;    
}
.Eye{
    position: relative;
    left: 85%;
 width: 30px;
height: 30px;
background: #C1EAC5;
border-radius: 36px;
padding: 2%;
}
.Eye2{
    position: relative;
    left: 85%;
 width: 30px;
height: 30px;
background: #FACDCD;
border-radius: 36px;
padding: 2%;
}