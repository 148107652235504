.Detailcomnder{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.txt4box4-dc{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7C5E10;
}
.col05-dc{
    display: flex;
    flex-direction: column;
    gap: 2em; 
}
.colbox4-dc{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.txt1-dc{
    
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 40px;
color: #222222;
}
.rowbox-dc{
    display: flex;
    flex-direction: row;
    gap:1em;
}
.txtbox-dc{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: #222222;

}

.row1-dc{
    display: flex;
    flex-direction: row;
   justify-content: space-between;
}
.col1-dc{
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.border-dc{
    width: 8px;
height: 145px;

background: #F7D070;
border-radius: 8px 0px 0px 8px;
}
.colbox1-dc{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 4%;
}
.box1-dc{
width: 362px;
height: auto;
background: #FFFFFF;
border-radius: 8px;
display: flex;
flex-direction: row;
gap: 1em;


}
.txtbox1-ddc{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;
color: #222222;
}
.ul-dc{
    list-style:disc;
    padding-left: 5%;
}
.txtli-dc{
    width: 300%;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #222222;
mix-blend-mode: normal;
}
.txtspanli-dc{
 
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #4098D7;
mix-blend-mode: normal;
}
.box3-dc{
    width: 358px;
height: auto;
background: #FFFFFF;
border-radius: 8px;
display: flex;
flex-direction: column;
gap: 1.7em;
padding-left: 4%;
padding-top: 5%;
padding-bottom: 5%;
}
.box2-dc{
width: 358px;
height: auto;
padding-bottom: 4%;
background: #FFFFFF;
border-radius: 8px;
display: flex;
flex-direction: column;
gap: 1.7em;
padding-left: 4%;
padding-top: 5%;
}
.cirl-dc{
    width: 16px;
height: 16px;
border-radius: 100%;
background: #F7D070;
margin-top: 1%;
}
.cirl-dc4{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #D64545;
    margin-top: 1%;
}
.cirl-dc2{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #4098D7;
    margin-top: 1%;
}
.cirl-dc3{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #57AE5B;
        margin-top: 1%;
}
.txt3box3-dc{

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #222222;
mix-blend-mode: normal;
}
.txtbox2-dc{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #222222;
    
}
.img-dc{
    Width:24px;
Height:24px;
border-radius: 100px;

}
.minirow-dc{
    display: flex;
flex-direction: row;
gap: 1em;
align-items: center;
}
th {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7E7E7E;
    border-top: #F7F7F7 solid 1px;
    height: 56px;
    padding-bottom: 5%;
}
.bnt-dl{
    width: 200px;
    height: 40px;
    left: 1212px;
    top: 76px;
    

    
    background: #E3F9E5;

    
    border: 1px solid #05400A;
    border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
cursor: pointer;
text-align: center;
color: #05400A;
}
.row12-dl{
    display: flex;
    flex-direction: row;
justify-content: space-between;
}
.txt1box2-dc{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #222222;
mix-blend-mode: normal;
}
.txt1box3-dc{ 
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #222222;
mix-blend-mode: normal;
}
.txt4box3-dc{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
color: #222222;
mix-blend-mode: normal;

}
.txtbox5-dc{
 font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;
color: #F7F7F7;
margin-top: 3%;
}
.box4-dc{
    display: flex;
flex-direction: column;
gap: 1.7em;
width: 728px;
height: 640px;
background: #FFFFFF;
border-radius: 8px;
padding-top: 1.5%;
padding-left: 3%;
}
.rowbox4-dc{
    display: flex;
    flex-direction: row;
    gap:14em;;
}
.txtbox4-dc{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #626262;

}
.linebox4-dc{
width: 706px;
height: 0px;
border: 1px solid #EDEDED;
}
.row2box4-dc{
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}
.imgbox4-dc{
Width:76px;
Height:112px;
mix-blend-mode: normal;
border-radius: 8px;
}
.txt3box4-dc{
    color: var(--black-maktaba, #222);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; 
    width: 162px; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    }
    .widthtd-dc{
        width: 42.5%;
    }
    .widthtd1-dc{
        width: 37%;
    }
    .box5-dc{
width: 738px;
height: 164px;
background: #222222;
border-radius: 8px;
    }
    .rowbox5-dc{
display: flex;
flex-direction: row;
justify-content: center;
gap:1em;
    }
    .txttab-dc{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #222222;
   
    }
    .bntbox5-dc{
width: 160px;
height: 48px;
background: #626262;
border-radius: 8px;
color: #FFFFFF;
cursor: pointer;
    }
    .bntbox6-dc{
        width: 160px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 8px;
       cursor: pointer;
            }
            .row01box5-dc{
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 2em;
            }   
.scroll-div2{
    overflow: auto;
    text-align: justify;
    padding: 20px;
    height: 580px;
    margin-left: -15px;
    overflow-x: hidden;
    
  
}
.scroll-div2::-webkit-scrollbar {
width: 12px;
}      
.tab-prd{
    width: 677px;
} 