.resf{
    width: 100%;
height: 100%;
flex-shrink: 0;
background: #222;
display: flex;
flex-direction: column;
gap: 2em;
justify-content: center;
align-items: center;
}
.txtresf1{
    width: 328px;
color: #FFF;

text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px;
}
.colrespof{
    display: flex;
    gap:1em;
}
.lineres{
    width: 360px;
height: 1px;
flex-shrink: 0;
opacity: 0.1;
background: #F7F7F7;
}
.txtref4{
 color: #FFF;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
opacity: 0.8;
margin-bottom: 5%;
}
.flexres{
    display: flex;
    gap:1.5em;
    justify-content: center;
    align-items: center;
}
.txtref2{
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.8;
}
.txtref3{
    color: #FFF;
font-family: Merriweather;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.white-link {
    color: white;
    text-decoration: none; /* Optional: removes the underline from the link */
  }
  
  .white-link:hover {
    text-decoration: underline; /* Optional: adds an underline on hover */
  }
  