.txt-c{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #222222;
    
}

.backnovo-c0{cursor: pointer;
}



.liste-c{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
th {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7E7E7E;
    border-top: #F7F7F7 solid 1px;
    height: 56px;
}
.txtbnt01-lc{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #05400A;
}
.txtbnt02-lc{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;

text-align: center;

color: #003E6B;
}
.bnt02-lc{
border: none;
    width: 88px;
height: 32px;
border: none;
background: #B6E0FE;
border-radius: 36px;
}
.txtbnt01-lc{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
color: #05400A;
}
.bnt01-lc{
    width: 88px;
    height: 32px;
    left: 1220px;
    top: 370px;
    background: #C1EAC5;
    border-radius: 36px;
    border: none;
}
td {
    height: 60px;
    border-top: #F7F7F7 solid 2px;
}
.box01-lv{
    width: auto;
height: auto;
background: #FFFFFF;
border-radius: 8px;
display: flex;
flex-direction: column;
gap: 1em;
padding: 2%;
}
.pagination1-c{
    display: flex;
flex-direction: row;
gap: 1em;
}
.page-c{
    display: flex;
    justify-content: center;
}
.txttabs-c{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
width:100%;
}




.tdwidth{
    width: 15%;
}
.tdwidth02{
    width: 27%;
}
.img1-c{
    background-blend-mode: multiply, normal;
mix-blend-mode: normal;
border-radius: 4px;
Width:40px;
Height:40px;
}
.row-c{
    display: flex;
    flex-direction:row ;
    gap: 1em;
    align-items: center;
}
.tdwidth1{
    width: 14%;
}
.txt02-c{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
text-align: left;
color: #7C5E10;

}
.row01-c{
    display: flex;
    flex-direction:column ;
    gap: 3.5em;
}