.pf{

    display: flex;
    flex-direction: column;
    gap: 2em;

}
.txt-pf{
    color: var(--neutral-0, #222);
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 48px;
}
.butt-vp{
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 132px;
    height: 40px;
    background: #FFFAEB;
    border: 1px solid #E9B949;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;

}
.box-pf{
height: auto;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
padding: 2%;
}
.rowpass2-pf{
    display: flex;
    justify-content: end;
}
.row-box{
    display: flex;
    justify-content: space-between;
    padding-right: 25%;
}
.vp{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.txt-select-vp{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #222222;
}
.txt1-vp{
 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #222222;
}
.box1-vp{
    padding: 2%;
width: 1128px;
height: auto;
background: #FFFFFF;
border-radius: 8px;
display: flex;
flex-direction: column;
gap: 4em;
}

.blo-dv{
    width: 100%;
    display: flex;
    justify-content: end;
}
.txtbnt-vp{

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: #222222;

}

input:focus { 
    outline: none !important;
    border-color: #caa651;
    box-shadow:  #caa651 solid 4px;
 }
 .deconnecter-box{margin-top: 420%;}
.input-provp{
    width: 400px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    padding: 1.5%;
}
.txtpass-pf{
    color: var(--neutral-0, #222);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}
.txt04-vp{
    color: var(--neutral-0, #222);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
padding-bottom: 0.5%;
}
.txtpass2-pf{
    color: var(--neutral-300, #626262);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}
.pass-pf{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.rowpass-pf{
    display: flex;
    justify-content: end;

}
.bntpasww3{
    cursor: pointer;
    width: 124px;
height: 48px;
flex-shrink: 0;
border-radius: 8px;
background: var(--neutral-0, #222);
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
}
.bntpasww2{
    cursor: pointer;
    width: 124px;
height: 48px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--neutral-0, #222);
background: #FFF;
color: var(--neutral-0, #222);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px


}
.txttabs-c{
color: var(--neutral-0, #222);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px;
}
.txtpo{
    color: var(--neutral-0, #222);
font-size: 32px;
font-family: Inter;
font-style: normal;
font-weight: 600;
line-height: 48px;
}
.box2row-vp{
    padding: 4%;
    width: auto;
height: auto;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
    display: flex;
    flex-direction: row;
  justify-content: space-between;
}
.box1row-vp{
    display: flex;
    flex-direction: row;
    gap: 5em;
}
.pbox-vp{
    width: 40px;
    height: 40px;
    background: #FCEFC7;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}
.col3-dv{
    display: flex;
    flex-direction: column;
    gap: 2em;  
}
.col1-vp{
    display: flex;
flex-direction: column;
gap: 1em;
}
.txt2-vp{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;
color: #000000;
}
.txt3-vp{
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    }

    .txt4-vp
    {
        color: var(--neutral-0, #222);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
padding-bottom: 2%;
    }
    .uplod{
        visibility: hidden;
        display: none;
    }
.labelup{cursor: pointer;}
    .circle-vp{
     
        border-radius: 100%;
    }
    .boxgrprof-vp{
        width: 150px;
height: 150px;
margin-top: 1%;
border-radius: 50%;
background: #CFCFCF;
    }
    .rowbntavatr-vp{
        display: flex;
        gap: 1em;
    }
    .bnt10-avatar-vp{
        padding-left: 3%;
        width: 159px;
        height: 36px;
       display: flex;
       background: #FFFFFF;
cursor: pointer;
border: 1px solid #E1E1E1;
border-radius: 8px;
    }
    .bnt20-avatar-vp{
        padding-left: 3%;
        width: 153px;
        height: 36px;
        display: flex;
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        border-radius: 8px;
        cursor: pointer;
            }
    .txtavatar1-vp{
        width: 134px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #222222;

    }
    .avrt2-page{

margin-left: 40%;
    }
    .css-19kzrtu{
        margin-top: 5%;
    }
    .circle2-vp{
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:0.5em;

    }
    .icon-vp{
        margin-left: 25%;
        margin-top: 55%;
    }
    .col4-vp{
        display: flex;
        flex-direction: column;
        gap: 2em;
    }
    .txt-vp{

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #626262;
        
    }
    .input-vp{
        width: 500px;
        height: 58px;
        background: #FFFFFF;
        border: 1px solid #E1E1E1;
        border-radius: 8px;
    }
    .inputdate-vp{
        width: 500px;
        height: 58px;
        background: #FFFFFF;
        border: 1px solid #7e7e7e;
        border-radius: 8px;
        padding: 3%;
    }
    .col20-vp{
        flex-direction: column;
        display: flex;
        text-align: left;
        gap: 0.5em;
    }
    .lin-vp{
width: 1072px;
height: 0px;
border: 1px solid #E1E1E1;
    }
    .txt102av-vp{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #222222;
        margin-left: 6%;
        margin-top: 6%;
    }
    .box2row-vc{
        padding: 5%;
    }
    .txt103av-vp{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #D64545;
        margin-left: 6%;
        margin-top: 6%;
    }