.tdwidth2-aviss{
    width: 30%;
}
.comm-aviss{
width: 318px;
height: 32px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 16px;
color: #222222;
margin-top: 2%;
}
.txt0014-aviss{
    
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
color: #222222;
}
.tdwidth3-aviss{
    display: flex;
    flex-direction: row;
gap: 1em;
}
.img-aviss{
    width: 32px;
height: 32px;
    background-blend-mode: multiply, normal;
    mix-blend-mode: normal;
    border-radius: 4px;
    border-radius: 100%; 

}
.tdwidth10-aviss{
    width: 18%;
}
.tdwidth02-aviss{
    width: 20%;
}
.tdwidth110-aviss{
    width: 1%;

}