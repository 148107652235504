.Details_client{
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.rowbox-dc {
    display: flex;
    flex-direction: row;
    gap: 1em;
}
.avtar-Details_client{
    width: 40px;
height: 40px;
flex-shrink: 0;
border-radius: 40px;
}
.mcol-Details_client{
    display: flex;
    flex-direction: column;
    gap:2em;
}
.txttabs-Details_client{
    color: var(--neutral-0, #222);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px;
}
.box-Details_client{
    width: auto;
height: auto;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
padding: 2%;

}
.txt-Details_client{
    color: var(--neutral-0, #222);
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 40px; 
}
.txtbox-dc {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #222222;
}
.mrow-Details_client{
    display: flex;
    gap: 1em;
    align-items: center;
}