.row1-fil{
    display: flex;
    justify-content: space-between;
}

.row2-int{
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: center;
    align-items: center;
}
.bnt0-int{
    display: flex;
    flex-direction: row;
 ;
    cursor: pointer;
    width: 138px;
height: 48px;
justify-content: center;
align-items: center;
background: #FFFFFF;
border: 1px solid #515151;
border-radius: 8px;
}
.txt0-int{
    width: 72px;
margin-top: 0.5%;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 150%;

color: #515151;
}
.input-comd{
    width: 240px;
height: 48px;


background: #FFFFFF;

border: 1px solid #E1E1E1;
border-radius: 8px;
}
.input-int{
    width: 440px;
height: 48px;
background: #FFFFFF;
border: none;
border-radius: 8px;
}
.select-int{
    width: 125px;
height: 36px;
margin-top: 2%;
background: #FFFFFF;
border: 1px solid #CFCFCF;
border-radius: 8px;
}
.txt2-int{
    width: 100%;
}