.titrev{
    color: #222222;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
}



.produitv{
    width: auto; 
}
.produit .item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
   
}
.Continuerv{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    box-sizing: border-box;
    width: 249px;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #C4CBCD;
    border-radius: 8px;
    color: #222;
}
.Continuer .icon1{
    font-size: 12px;
    margin-top: 19px;
    margin-left: 26px;
    color: #757E78;
}
.Continuer a{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
color: #222222;
margin-top: 15px;
}
.row-cart{
    display: flex;
    justify-content: space-between;
}
.Cartv{
    display: flex;
   flex-direction: column;
   gap: 5em;
    padding-top: 5%;
}
.box3 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
    
   
}
.btn3{
    color:#757E78 ; 
    margin-left: 165px; ;    
}

.scroll{
        width: 800px;
        height: 150px;
        overflow: auto;
        text-align: justify;
        padding: 20px;
        height: 580px;
        margin-left: -15px;
        overflow-x: hidden;
      
}
.scroll::-webkit-scrollbar {
    width: 12px;
}


.Qte1{
    margin-left: 332px;
}
.total1{
    margin-left: 145px;
}
.box3{
    margin-top: 16px;
}
