* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  }
  html {
    height: 100vh; 
}
body {
  overflow: visible;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
  overflow-x:hidden;

}
.contente {
  height: 730px;
  width: 100%;
  overflow-x: hidden;
  overflow-y:visible ;
  padding-right: 2%;
}


body::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
  border-radius: 10px;

}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}


body::-webkit-scrollbar {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;

}



@media (max-width: 667px) {
  body, html  {overflow-x:hidden;}
}

p,h1{
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
