.responsivecard{
    padding-top: 30%;
    padding-bottom: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
 gap: 3em;
 padding-left: 7%;
 padding-right: 7%;
}

@media (max-width: 867px) {
    .resume{
 margin: 0% !important;
 
    }
}
.rec-res{
    display: flex;
    justify-content: center;
}
.txt-respon{
    color: #222;
font-family: Merriweather;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
}
.row-responsivecard{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.lin-resp{
    width: 100%;
height: 0px;
flex-shrink: 0;
stroke-width: 1px;
stroke: var(--neutral-800, #E1E1E1);

}
.row-responsivecard2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 119px;
}

.img-res{
    width: 40px;
height: 40px;
flex-shrink: 0;
border-radius: 8px;
background-blend-mode: multiply, normal;
}
.col-responsivecard{
    display: flex;
  flex-direction: column;
  gap: 2em;

}
.txt-respon2{
    color: var(--black-maktaba, #222);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
text-transform: uppercase;
width: 100px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}
.col2-responsivecard{
    display: flex;
    flex-direction: column;
    gap: 2em;

}
.row2-res{
    display: flex;
justify-content: space-between;
}
.box1-res{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.txt3-rescard{
    color: var(--neutral-300, #626262);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22px; 
}
.txt4-rescard{
    color: var(--black-maktaba, #222);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.box2-res{
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
}