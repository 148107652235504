
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a{
	text-decoration: none;	
}
.patch-txt{
	position: relative;
	top:19%;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 12px;

text-align: center;

color: #222222;
}
.patch{
	position: relative;
    bottom: 48px;
    left: 50%;
	width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    color: #fff;
    font-size: 12px;
	background: #E9B949;

}

.row1-profile{
    flex-direction: row;
    display: flex;
    text-align: left;
    gap:1em;

}
.avrt-pagenave{
	margin-top: 1%;
}
.iconcolor{
	color: #FFFFFF;
}
.butto-NAV-link{
	cursor: pointer;
}
.butto-NAV-link-none{
	display: none;
}

.main-nav .material-symbols-outlined{
	margin-top: 39px;
	margin-left: -30px;

	color: #FFFFFF;
}
.main-nav a li  {
	
	padding: 20px;
	color: #FFFFFF;
}
.conx{
	margin-top: -2%;
}
button p{
	font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: center;

color: #1A110A;
}
.main-nav a li:hover {
	color: #ffc125 !important;
}
.header {
  background: #222222;
  position: fixed;
	top: 0;
	width:100%;
	z-index: 9999;
	height: 77px;
}
.header a {
	color: #1A110A ;
}
.cartchop{
	
	margin-top: 13%;
}
@media (min-width: 769px) {
	.header,
	.main-nav {
		display: flex;
		align-items: center;
	}

}
@media (max-width: 768px) {
	.section_user{
		display: none !important;
	}
	.dropdown-content a:hover {background-color:  #E9B949;color: #fff;}
  }

.icon-menu{	
display: none;
}
@media (max-width: 860px) {
	.main-nav a li ,.butto-NAV{
		display: none;
	}
	.css-h4y409-MuiList-root {

		
		display: flex;
		justify-content: space-between!important;
		flex-direction: column!important;
		height: 100% !important;
	}
	.css-vubbuv {
	
		color: #fff!important;}
	.css-22u6wc .MuiDrawer-paper {

		background: #222!important;

	}
	.hding{
		display: none;
	}
	.inlinke {	
        min-width: 200px;
        max-height: 73px;
        overflow-y: auto;
     
    }

	.inlinke a{
		color: #222!important;
text-align: left !important;
font-family: Inter!important;
font-size: 12px!important;
font-style: normal!important;
font-weight: 500!important;
line-height: normal!important;

	}
	.header a ,.css-i4bv87-MuiSvgIcon-root{
		color: #fff !important;
	}
	.css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper{
		gap: 1em !important;
		background-color: #222222;
	}
	.forlinke a{
		color: #fff!important;
text-align: left !important;
font-family: Inter!important;
font-size: 12px!important;
font-style: normal!important;
font-weight: 500!important;
line-height: normal!important;
background-color:  #E9B949;
	}
	.group032{
	gap:0em !important
	}
	.header{
		display: flex  !important;
		width: 100%  !important;
height: 72px  !important;
justify-content: space-between;
align-items: center;
padding-left: 5%;
padding-right: 5%;
	}
	.cartchop {
		margin-top: 24px  !important;
	}
.logo2{
	width: 75.429px;
height: 32px;
flex-shrink: 0;
}
.logo3{
		display: flex;
		justify-content: center;
		height: 39px;
		padding-bottom: -4%;
		margin-top: 43%;
		box-shadow: none;
}
	.icon-menu{	
		display: block;
		color: #FFFFFF;
		width: 89px !important;
	}  
	
}

.dropbtn {
	color: white;
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
  }
  
  .dropdown {
	position: relative;
	display: inline-block;
  }
  
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	min-width: 300px;
    max-height: 450px; /* You can adjust this value as needed */
    overflow-y: auto;
  }
  
  .dropdown-content a {
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  .dropdown-content a:hover {background-color:  #E9B949;color: #fff;}
  
  .dropdown:hover .dropdown-content {
	display: block;
  }
  
  .dropdown:hover .dropbtn {
	color: #E9B949;
  }
.txt-content{
	font-family: "Nunito", Sans-serif;
    font-size:10.5px;
}

.butto-NAV{
	cursor: pointer;
width:162px;
Height:44px;
background: #E9B949;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.102437);
border-radius: 8px;

}

@media (min-width: 1025px) {
	.header {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 5em;
		
	}

}
.group032{
	display: flex;
	gap:3em;
	align-items: center;
	justify-content: center;
}
.group0{
	display: flex;
	gap:2em;
	align-items: center;
	justify-content: center;
}
.group{
	display: flex;
	gap: 2rem;
	margin-left: -3%;
}

.section_user{
	display: flex;
}
.section_user-none{
	display: none;
}
.section_user .username{
	color: #FFF;
	text-align: right;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
margin-right: 20px;
text-overflow: ellipsis;
overflow: hidden;
max-width: 100px;
max-height: 20px;
}
.username2{
    color: #fff;
	text-align: right;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
text-overflow: ellipsis;
overflow: hidden;
max-width: 100px;
max-height: 20px;
}
