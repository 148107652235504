.Factorisation{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.txt1-Factr{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #222222;
}
.box1-Factr{
    Width:auto;
    height:auto;
background: #FFFFFF;
border-bottom: 1px solid #F7F7F7;
border-radius: 8px;
padding-left: 5%;
}
.txt2-Factr{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #222222;

}
.bnt101-Factr{
width: 58px;
height: 22px;
background: #FFFFFF;
border: 0.785714px solid #A27C1A;
border-radius: 18.8571px;
display: flex;
}
.txtbnt101-Factr{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #A27C1A;
}
.tickbnt101-Factr{
    margin-left: 8%;
    margin-top: -2.5%;
}
th {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7E7E7E;
    border-top: #F7F7F7 solid 1px;
    height: 56px;
}
td {
    height: 60px;
    border-top: #F7F7F7 solid 2px;
}
.row212-Factr{
display: flex;
gap: 1em;
}
.bnt-Factorisation{
    width: 237px;
height: 48px;
flex-shrink: 0;
border-radius: 8px;
background: var(--neutral-0, #222);
color: #FFF;
display: flex;
justify-content: center;
align-items: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
cursor: pointer;
}
.txt147-Factr{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #222222;

}
.tdwidth1-Factr{
    width: 10%;
}
.Factorisationend{
    display: flex;
    justify-content: end;
    padding-bottom: 4%;
}